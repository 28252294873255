<template>
    <div v-bind="$attrs" class="img-view-div" @click="setSelected()" :class="{ 'border border-primary border-2': selected }" @mouseover="mouseover" @mouseleave="mouseleave" v-if="checkExtension()">
        <input type="checkbox" class="img-view-checkbox" v-model="selected">
        <i class="bi bi-arrows-angle-expand img-view-ellipsis px-2" style="cursor: pointer;" @click.stop.prevent="openImageView"></i>
        <img v-show="imgLoaded" class="rounded" @error="imgLoaded = true" @load="imgLoaded = true" :src="`/nt/api/file/view/sviw_Image_Images2/${row.PrimKey}/${row.FileName}?height=150`">
        <div v-if="!imgLoaded" class="placeholder-glow rounded" style="height: 150px; width: 225px;">
            <div class="w-100 h-100 bg-secondary placeholder"></div>
        </div>
        <div class="text-truncate" style="max-width: 150px;">
            <small :title="row.FileName">{{ row.FileName }}</small>
        </div>
    </div>

    <OModal ref="imageModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ row.FileName }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                    <img v-show="bigImgLoaded" @error="bigImgLoaded = true" style="width: 525px;object-fit: contain;" @load="bigImgLoaded = true" :src="`/nt/api/file/view/sviw_Image_Images2/${row.PrimKey}/${row.FileName}`">
                    <div v-if="!bigImgLoaded" class="placeholder-glow rounded" style="height: 450px; width: 525px;">
                        <div class="w-100 h-100 bg-secondary placeholder"></div>
                    </div>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, defineProps } from 'vue';

    const imgLoaded = ref(false);
    const bigImgLoaded = ref(false);
    const hover = ref(false);
    const selected = ref(false);
    const display = ref('none');
    const imageModal = ref();

    const emit = defineEmits(['imageSelected', 'imageRemoved']);

    const props = defineProps({
        row: Object
    });

    function openImageView(){
        imageModal.value?.show();
    }

    function mouseover(){
        hover.value = true;
        display.value = 'block';
    }

    function mouseleave(){
        hover.value = false;
        if(selected.value == false){
            display.value = 'none';
        }
    }

    function setSelected(){
        if(selected.value == false){
            selected.value = true;
            emit('imageSelected', props.row);
        } else {
            selected.value = false;
            emit('imageRemoved', props.row);
        }
    }

    function checkExtension(){
        return props.row.Extension == 'png' 
        || props.row.Extension == 'jpeg' 
        || props.row.Extension == 'jpg' 
        || props.row.Extension == 'svg' 
        || props.row.Extension == 'gif' 
        || props.row.Extension == '' 
        || props.row.Extension == 'jfif';
    }  
</script>

<style scoped>
    .img-view-checkbox {
        top: 8px;
        left: 8px;
        width: 18px;
        height: 18px;
        position: absolute;
        display: v-bind(display);
        cursor: pointer;
    }

    .img-view-ellipsis {
        top: 8px;
        right: 8px;
        position: absolute;
        display: none;
        color: white;
    }

    .img-view-div:hover .img-view-checkbox, .img-view-div:hover .img-view-ellipsis {
        display: v-bind(display);
    }

    .img-view-div {
        position: relative;
    }
</style>